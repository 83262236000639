//Permisos por grupo:

export const hasPanelAccess = role => {
    switch (role) {
        case 'Administrador':
            return true;

        case 'Supervisor':
            return true;

        case 'Agente':
            return true;

        case 'Sub Agente':
            return true;
            
        default:
            return false;
    }
}

export const hasHighAccess = role => (role === 'Administrador' || role === 'Supervisor')  ? true : false;

export const isPDVorCS = role => (role === 'Punto Venta' || role === 'Customer Service')  ? true : false;

//Permisos individuales
export const isAdmin = role => role === 'Administrador' ? true : false;
export const isSupervisor = role => role === 'Supervisor' ? true : false;
export const isAgent = role => role === 'Agente' ? true : false;
export const isSubAgent = role => role === 'Sub Agente' ? true : false;

export const isPDV = role => role === 'Punto Venta' ? true : false;
export const isCS = role => role === 'Customer Service' ? true : false;
export const isClient = role => role === 'Cliente' ? true : false;
export const isWatchmaker = role => role === 'Relojero' ? true : false;