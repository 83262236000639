import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { PATHS } from '../../../types/paths';
import { Loader } from '../../Loader/Loader';
import { TicketCreateForm } from './TicketCreate/TicketCreateForm'
import { TicketDataFilter } from './TicketTable/TicketDataFilter';
import { TicketDataTable } from './TicketTable/TicketDataTable'

export const ClientTicketPanel = () => {
    const {ticket_count,title} = useSelector(state => state.header);
    const {loading} = useSelector(state => state.ui);
    
    const [filterText, setFilterText] = useState('');

    return (
        <div className='seccion-tabla panel_cliente'>
            <div className='seccion-tabla-interno'>
                <div className='panel_cliente_marco_interno'>
                    <div className='panel_cliente_izquierda'>
                        <div className='panel_cliente_titulo'>
                            <h2>{loading ? <Loader /> : title}</h2>
                        </div>
                        <TicketDataFilter 
                            filterText={filterText} 
                            setFilterText={setFilterText} 
                        />
                        <div className='panel_cliente_crear_ticket'>
                            <TicketCreateForm />
                        </div>
                    </div>
                    <div className='panel_cliente_derecha'>
                        <div className='panel_cliente_tipo_ticket'>
                            <ul>
                                <NavLink 
                                    activeClassName='panel_cliente_tipo_activo'
                                    to={PATHS.clientGeneralTickets}
                                >
                                    Mis tickets({ticket_count && ticket_count.general})
                                </NavLink>

                                <NavLink 
                                    activeClassName='panel_cliente_tipo_activo'
                                    to={PATHS.clientPendantTickets}
                                >
                                    Pendientes({ticket_count && ticket_count.pendant})
                                </NavLink>

                                <NavLink 
                                    activeClassName='panel_cliente_tipo_activo'
                                    to={PATHS.clientSolvedTickets}
                                >
                                    Solucionados({ticket_count && ticket_count.solved})
                                </NavLink>

                                <NavLink 
                                    activeClassName='panel_cliente_tipo_activo'
                                    to={PATHS.clientClosedTickets}
                                >
                                    Cerrados({ticket_count && ticket_count.closed})
                                </NavLink>

                            </ul>
                        </div>
                        <div className='panel_cliente_tabla'>
                            <TicketDataTable filterText={filterText} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
            
    )
}
