import React from 'react'
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

export const PublicRouter = ({
    isAuth, 
    component: Component,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            component={(props) => {
                return (!isAuth)
                    ? <Component {...props} />
                    : <Redirect 
                        to={{
                            pathname:'/panel',
                            state:{
                                from: props.location
                            }
                        }}  
                    />}
            }
        />
    )
}

PublicRouter.propTypes = {
    isAuth: PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired
}