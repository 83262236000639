import { TYPES } from "../types/types"

const iState = {
    loading: false,
    msgError: null,
    authMsg:null
}

export const uiReducer = ( state = iState, action ) => {

    switch (action.type) {
        case TYPES.uiError:
            return {
                ...state,
                msgError: action.payload
            }
        
        case TYPES.uiNoError:
            return {
                ...state,
                msgError: null
            }

            case TYPES.uiAuthError:
                return {
                    ...state,
                    authMsg: action.payload
                }
            
            case TYPES.uiAuthNoError:
                return {
                    ...state,
                    authMsg: null
                }

        case TYPES.uiLoading:
            return{
                ...state,
                loading: true
            }

        case TYPES.uiNotLoading:
            return{
                ...state,
                loading: false
            }

        default:
            return state;
    }

}