import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

//Tippy para react (Tooltips)
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

//logo
import logo from '../../Cteam logo.png'

//Redux actions
import { useDispatch } from 'react-redux';
import { startLogout } from '../../actions/auth';

import { PATHS } from '../../types/paths';

import { getTickets } from '../../actions/tickets';

import { setProfileInfo } from '../../actions/user/user-profile';
import { cleanTheStorage } from '../../helpers/clean_the_storage';

export const Nav = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(cleanTheStorage());
        switch (location.pathname) {
            /**CLIENTE */
            case PATHS.clientGeneralTickets:
                dispatch(getTickets('tickets'));
                break;
            
            case PATHS.clientSolvedTickets:
                dispatch(getTickets('solved'));
                break;
            
            case PATHS.clientClosedTickets:
                dispatch(getTickets('closed'));
                break;
        
            case PATHS.clientPendantTickets:
                dispatch(getTickets('pendant'));
                break;

            /**PERFIL DE USUARIO */
            case PATHS.panelConfigProfile:
                dispatch(setProfileInfo());
                break;

            default:
                break;
        }
    }, [location, dispatch])    
    
    const handleLogout = () => {
        dispatch( startLogout() );
    }
    const relocate = `${process.env.REACT_APP_CLIENT_TO_OT_URL}/panel?token=${localStorage.getItem('token')}`
    return (
        <nav className='navbar'>
            <div className='navbar-menu'>

                {/** BOTÓN PARA IR INICIO TICKETS*/}
                <Tippy content='Ir a inicio' placement='right'>
                    <a href={PATHS.clientGeneralTickets}>
                        <img src={ logo } alt='Logo C-team'  />
                    </a>
                </Tippy>

                {/**TICKETS*/}
                <Tippy content='Ir a tickets' placement='right'>
                    <Link 
                        to={PATHS.clientGeneralTickets} 
                        className='navbar-ticket-button' 
                    >
                        <i className="fas fa-ticket-alt"></i>
                    </Link>
                </Tippy>
                
                <Tippy content="Ir a OT's" placement='right'>
                    <a 
                        href={relocate} 
                        className='navbar-ticket-button' 
                    >
                        <i className="fas fa-clock"></i>
                    </a>
                </Tippy>    
            </div>

            <div className='navbar-user'>       

                {/** BOTÓN PARA CONFIGURACIÓN DE USUARIO */}
                <Tippy content='Ir al perfil de usuario' placement='right'>
                    <Link 
                        to={PATHS.panelConfigProfile} 
                        className='navbar-ticket-button' 
                    >
                        <i className="fas fa-user"></i>
                    </Link>
                </Tippy>

                {/** BOTÓN PARA CERRAR SESIÓN */}
                <Tippy content='Cerrar sesión actual' placement='right'>
                    <button 
                        className='navbar-logout-button' 
                        onClick={ handleLogout } 
                    >
                        <i className="fas fa-power-off"></i>
                    </button>
                </Tippy>
            </div>
        </nav>
    )
}
