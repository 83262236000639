import { TYPES } from "../types/types";

export const error = err => ({
    type: TYPES.uiError,
    payload: err
});

export const noError = () => ({
    type: TYPES.uiNoError   
});

export const authError = err => ({
    type: TYPES.uiAuthError,
    payload: err
});

export const noAuthError = () => ({
    type: TYPES.uiAuthNoError   
});


export const isLoading = () => ({
    type: TYPES.uiLoading
});

export const isNotLoading = () => ({
    type: TYPES.uiNotLoading
});