
import styled from 'styled-components';
export const TextField = styled.input`
height: 32px;
width: 200px;
border-radius: 0;
border-top-left-radius: 5px;
border: none;
border-bottom: 1px solid rgba(15,39,59,.2);
padding: 0 32px 0 16px;
transition: 0.3s all linear;

&:focus {
  color: rgba(15,39,59,.8);
  border-bottom: 1px solid rgba(15,39,59,.8);
}
`;

export const ClearButton = styled.button`
border-top-left-radius: 0;
border-bottom-left-radius: 0;
border-top-right-radius: 5px;
border-bottom-right-radius: 5px;
border: none;
color: #fff;
background: rgba(15,39,59,1);
height: 32px;
width: 32px;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
`;

export const ToRButton = styled.button`
  margin: 0 .5rem 0 0;
  border-radius: 5px;
  border: 1px solid var(--input-border);
  color: var(--ltcolor);
  padding: .4rem .5rem;
  margin: 0  0 0 .3rem;
  ${ props => (props.className.includes('take-submit') ) ? 
  'background: var(--orangecolor);' : 
  "background: var(--midcolor); border: 2px solid var(--midcolor);color: var(--ltcolor);box-shadow: inset 1px 1px 3px 1px black;"}
`;