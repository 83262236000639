import { TYPES } from "../types/types";

const initialState = {
    priorities:[],
    topics:[]
}

export const createReducer = (state = initialState, action) => {

    switch (action.type) {
        /**TICKETS PARA CLIENTES */
        case TYPES.setCreateClientTicket:
            return {
                ...state,
                priorities: [...action.payload.priorities],
                topics: [...action.payload.topics],
            }

        case TYPES.deleteCreationInfo:
            return {...initialState};

        default:
            return state;
    }
}