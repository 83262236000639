import { TYPES } from "../types/types";

const initialState = {
    detail: {},
    comments: {},
    interactions: [],
    time: 0,
    update_info:{}
}

export const detailReducer = (state = initialState, action) => {

    switch (action.type) {

        /** TICKETS TICKETS TICKETS TICKETS */
        case TYPES.setTicketDetail:
            return {
                ...state,
                detail: {...action.payload}
            };

        case TYPES.setTicketComments:
            return {
                ...state,
                comments: {...action.payload}
            };

        case TYPES.setTicketInteractions:
            return {
                ...state,
                interactions: {...action.payload}
            };

        case TYPES.setTicketTime:
            return {
                ...state,
                time: action.payload
            };

        case TYPES.updateTicketDetail:
            return {
                ...state,
                update_info: {
                    priority: action.payload.priority,
                    topic: action.payload.topic,
                    users: action.payload.users,
                    groups: action.payload.groups,
                }
            }
            
        case TYPES.deleteDetail:
            return {...initialState};

        default:
            return state;
    }
}