import Axios from 'axios';
import { TYPES } from '../types/types';
import { setHeader } from './header';
import { isLoading, isNotLoading } from './ui';

export const getTicketCompaniesAndPriorities = () => {
    return dispatch => {
        dispatch( isLoading() );
        
        Axios.get('api/tickets/create', {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then( res =>{ 
            console.log('recibir datos para crear', res.data.data);
            dispatch( setHeader(res.data.data.title, res.data.data.description) );
            dispatch( setTicketCompaniesAndPriorities(res.data.data.companies, res.data.data.priorities) );
            dispatch( isNotLoading() );
        })
        .catch( err => {
            console.log('error desde getcompanies and priorites',err);
            dispatch( isNotLoading() );
        })
    }
}

export const setTicketCompaniesAndPriorities = (companies, priorities) => ({
    type : TYPES.setCompanyPriority,
    payload : {
        companies,
        priorities
    }
})


//CLIENTE
export const getCreateClientTicketInfo = () => {
    return dispatch => {
        dispatch( isLoading() );

        Axios.get('api/customer/client_create', {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then( res => {
            const {priorities, topics} = res.data.data;
            dispatch( setCreateClientTicketInfo(priorities, topics) );
            dispatch( isNotLoading() );
        })
        .catch( err => {
            console.log(err);
            dispatch( isNotLoading() );
        })
    }
}

const setCreateClientTicketInfo = (priorities, topics) => ({
    type: TYPES.setCreateClientTicket,
    payload: {priorities, topics}
})

export const createClientTicket = fd => {
    return async(dispatch) => {
        dispatch( isLoading() );

        await Axios.post('api/customer/client_create', fd, {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then(res => {
            dispatch( isNotLoading() );
        })
        .catch(err => {
            alert('Falló la creación del ticket, intente nuevamente');
            dispatch( isNotLoading() );
        })
    }
}