import React from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

export const PrivateRouter = ({
    isAuth, 
    component: Component,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            component={(props) => (
                (isAuth)
                    ? <Component {...props} />
                    : <Redirect to={{
                        pathname:'/auth',
                        state:{
                            from: props.location
                        }
                    }} />
            )}
        />
    )
}

PrivateRouter.propTypes = {
    isAuth: PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired
}