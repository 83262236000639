import Axios from 'axios';
import { TYPES } from '../types/types';
import { setHeader } from './header';
import { isLoading, isNotLoading } from './ui';


export const loadTicket = (slug) => {
    return (dispatch) => {
        dispatch(isLoading());
        //console.log('cargando ticket')
        Axios.get(`api/customer/${slug}`,{
            headers:{
                'Authorization':'Bearer ' + localStorage.getItem('token')
            }
        })
            .then( res => {
                //console.log('respuesta', res.data)
                const { 
                    ticket, 
                    ticket_comments, 
                    ticket_interactions, 
                    ticket_time_spent, 
                    priorities,
                    topics,
                    users_to_derive,
                    groups_to_derive
                } = res.data.data;

                //console.log('Detalle de ticket',res.data.data)
                //Datos para el detalle
                dispatch(TicketDetail(ticket));
                dispatch(TicketComments(ticket_comments));
                dispatch(TicketInteractions(ticket_interactions));
                dispatch(TicketTime(ticket_time_spent));
                dispatch(loadTicketInfo(priorities, topics, users_to_derive, groups_to_derive));

                //Datos para el header
                dispatch(setHeader(res.data.data.title, res.data.data.description))
                dispatch(isNotLoading());
            })
            .catch( err => {
                console.log(err);
                dispatch(isNotLoading());
            } )
    }
}

export const updateTicketState = (slug, value) => {
    return async(dispatch) => {
        dispatch(isLoading());
        await Axios.post(`api/customer/${slug}/update`, value,{
            headers:{
                'Authorization':'Bearer ' + localStorage.getItem('token'),
            }
        })
        .then( () => {
            dispatch(isNotLoading());
        })
        .catch( err => {
            alert(err)
            dispatch(isNotLoading());
        } )
    }
}

export const TicketDetail = ticket => ({
    type: TYPES.setTicketDetail,
    payload: ticket
})

export const TicketComments = comments => ({
    type: TYPES.setTicketComments,
    payload: comments
})

export const TicketInteractions = (interactions) => ({
    type: TYPES.setTicketInteractions,
    payload: interactions
})

export const TicketTime = timeInMinutes => ({
    type: TYPES.setTicketTime,
    payload: timeInMinutes
})

export const loadTicketInfo = (priority, topic, users, groups) => ({
    type: TYPES.updateTicketDetail,
    payload:{
            priority: priority,
            topic: topic,
            users: users,
            groups: groups,
        }
})

export const deleteDetail = () => ({ type: TYPES.deleteDetail})