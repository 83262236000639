import Axios from 'axios';
import { TYPES } from '../types/types';
import { setHeader } from './header';
import { error, isLoading, isNotLoading } from './ui';

export const setGeneralTickets = () => {
    return async(dispatch) => {
        dispatch(isLoading());

        await Axios.get('api/tickets',{
            headers:{
                'Authorization':'Bearer ' + localStorage.getItem('token')
            }
        })
            .then( res => {
                //console.log(res.data.data)
                dispatch( uploadTickets(res.data.data.data));
                dispatch( setHeader(res.data.data.title, res.data.data.description) );
                dispatch( isNotLoading() );
            })
            .catch( err => {

                dispatch(error('Parece que no se puede procesar la petición de los tickets'));
                dispatch( isNotLoading() );
            })
        }
}

export const setOpenTickets = () => {
    return async(dispatch) => {
        dispatch(isLoading());

        await Axios.get('api/open',{
            headers:{
                'Authorization':'Bearer ' + localStorage.getItem('token')
            }
        })
            .then( res => {
                dispatch( uploadTickets(res.data.data.data.open_tickets));
                dispatch( setHeader(res.data.data.title, res.data.data.description) );
                dispatch( isNotLoading() );
            })
            .catch( err => {

                dispatch(error('Parece que no se puede procesar la petición de los tickets'));
                dispatch( isNotLoading() );
            })
        }
}

export const setSolvedTickets = () => {
    return async(dispatch) => {
        dispatch(isLoading());

        await Axios.get('api/solved',{
            headers:{
                'Authorization':'Bearer ' + localStorage.getItem('token')
            }
        })
            .then( res => {
                dispatch( uploadTickets(res.data.data.data.solved_tickets));
                dispatch( setHeader(res.data.data.title, res.data.data.description) );
                dispatch( isNotLoading() );
            })
            .catch( err => {

                dispatch(error('Parece que no se puede procesar la petición de los tickets'));
                dispatch( isNotLoading() );
            })
        }
}

export const setClosedTickets = () => {
    return async(dispatch) => {
        dispatch(isLoading());
        await Axios.get('api/closed',{
            headers:{
                'Authorization':'Bearer ' + localStorage.getItem('token')
            }
        })
            .then( res => {
                dispatch( uploadTickets(res.data.data.data.closed_tickets));
                dispatch( setHeader(res.data.data.title, res.data.data.description) );
                dispatch( isNotLoading() );
            })
            .catch( err => {

                dispatch(error('Parece que no se puede procesar la petición de los tickets'));
                dispatch( isNotLoading() );
            })
        }
}


export const setDerivedTickets = () => {
    return async(dispatch) => {
        dispatch(isLoading());

        await Axios.get('api/derived',{
            headers:{
                'Authorization':'Bearer ' + localStorage.getItem('token')
            }
        })
            .then( res => {
                //console.log('derivados',res.data.data)
                dispatch( uploadTickets(res.data.data.data.derived_tickets));
                dispatch( setHeader(res.data.data.title, res.data.data.description) );
                dispatch( isNotLoading() );
            })
            .catch( err => {

                dispatch(error('Parece que no se puede procesar la petición de los tickets'));
                dispatch( isNotLoading() );
            })
        }
}


export const setPendantTickets = () => {
    return async(dispatch) => {
        dispatch(isLoading());

        await Axios.get('api/pendant',{
            headers:{
                'Authorization':'Bearer ' + localStorage.getItem('token')
            }
        })
            .then( res => {
                dispatch( uploadTickets(res.data.data.data.pendant_tickets));
                dispatch( setHeader(res.data.data.title, res.data.data.description) );
                dispatch( isNotLoading() );
            })
            .catch( err => {

                dispatch(error('Parece que no se puede procesar la petición de los tickets'));
                dispatch( isNotLoading() );
            })
        }
}

export const uploadTickets = (tickets) => ({
    type: TYPES.setTickets,
    payload: tickets
})


export const setTakeOrRelease = (slug, value) => {
    return async(dispatch) => {
        //console.log('value', value);
        dispatch(isLoading());        
        await Axios.post(`api/tickets/${slug}/update`, value,{
            headers:{
                'Authorization':'Bearer ' + localStorage.getItem('token')
            }
        })
        .then( res => {
            //alert('Cambio de estado del ticket realizado');
            dispatch( isNotLoading() );
        })
        .catch( err => {
            dispatch( isNotLoading() );
            alert('Intente nuevamente el cambio de estado del ticket')
            //dispatch(error('Intente nuevamente el cambio de estado del ticket'));
        } )

    }
};

export const getTicketCount = () => {
    return async(dispatch) => {
        await Axios.get('api/tickets/get_count',{
            headers:{
                'Authorization':'Bearer ' + localStorage.getItem('token')
            }
        })
            .then( res => {
                //console.log('data',res.data.tickets_count)
                dispatch(setTicketsCount(res.data.tickets_count))
            })
            .catch( err => {
                console.log('Parece que no se puede procesar la petición de los tickets');
            })
    }

}

export const getTickets = (url) => {
    return async(dispatch) => {
        dispatch(isLoading());

        await Axios.get(`api/customer/${url}`,{
            headers:{
                'Authorization':'Bearer ' + localStorage.getItem('token')
            }
        })
            .then( res => {
                dispatch( uploadTickets(res.data.data.data));
                dispatch( setHeader(res.data.data.title, res.data.data.description) );
                dispatch( isNotLoading() );
            })
            .catch( err => {
                dispatch(error('Parece que no se puede procesar la petición de los tickets'));
                dispatch( isNotLoading() );
            })
        }
}

const setTicketsCount = (ticketsCount) => ({
    type: TYPES.getTicketsCount,
    payload: ticketsCount
});

export const deleteAllTickets = () => ({
    type: TYPES.deleteTickets
})