import { deleteInfoHeader } from "../actions/header";
import { deleteAllTickets, getTicketCount } from "../actions/tickets";
import { deleteDetail } from "../actions/ticket-detail";

export const cleanTheStorage = () => {
    return (dispatch) => {
        dispatch(deleteAllTickets());
        dispatch(deleteInfoHeader());
        dispatch(deleteDetail());
        dispatch(getTicketCount());
    }
}