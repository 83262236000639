import React from 'react';
import { 
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { PATHS } from '../types/paths';

import { getTicketCount } from '../actions/tickets';

import { Nav } from './NavBar/Nav';
import { ClientTicketPanel } from './Clients/ClientTicket/ClientTicketPanel';
import { DetalleTicket } from './DetalleTicket';
import { Perfil } from './Perfil';

const Panel = () => {  
    const dispatch = useDispatch();
    return (
        <Router>
            <div className='panel-general'>
                <Nav />
                <Switch>
                    <Route //RUTA PARA DETALLES DE TICKETS
                        path={PATHS.detailTickets} 
                        component={DetalleTicket} 
                    />

                    <Route //RUTA PARA CONFIGURACIONES DE PERFIL
                        exact 
                        path={PATHS.panelConfigProfile} 
                        component={Perfil} 
                    />   
                    <Route //RUTA PARA BÚSQUEDA PERSONALIZADA
                        exact 
                        path={PATHS.clientTickets} 
                        component={ClientTicketPanel} 
                    />

                    <Redirect to={PATHS.clientGeneralTickets} />
                </Switch>    
            </div>
        </Router>
    )
}

export default Panel