import React from 'react'
import DataTable from 'react-data-table-component'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { formatDateWithTime } from '../../../../helpers/helpers';
import { Loader } from '../../../Loader/Loader';


export const TicketDataTable = ({filterText}) => {
    let auxTickets, filteredItems;

    const history = useHistory();
    const {tickets} = useSelector(state => state.storage);
    const { loading } = useSelector(state => state.ui)
    
    if ( tickets.length > 0 ) {
        auxTickets = tickets.map( (ticket) => {
            return {
                slug: ticket.slug, 
                title: ticket.title,
                topic: (ticket.topic ? ticket.topic.name : '-'),
                user: (ticket.user ? ticket.user.username : '-' ),
                date: formatDateWithTime(ticket.created_at),
                priority: ticket.priority.name,
                status: ticket.status.name,
                is_taken: ticket.is_taken,
                agent: ticket.agent
            }
        })

        //console.log(auxTickets[0].slug, "slug")
        filteredItems = auxTickets.filter(item => (item.slug && (item.slug.toString()).includes(filterText))
            || (item.title && item.title.toLowerCase().includes(filterText.toLowerCase())) 
            || (item.topic && item.topic.toLowerCase().includes(filterText.toLowerCase())) 
            || (item.user && item.user.toLowerCase().includes(filterText.toLowerCase())) 
            || (item.date && item.date.includes(filterText)) 
            || (item.priority && item.priority.toLowerCase().includes(filterText.toLowerCase())) 
            || (item.status && item.status.toLowerCase().includes(filterText.toLowerCase())) 
        );

        
    }
     
    const cols = [
        {   
            grow: 1,
            name:'#',
            selector:'slug',
            sortable: true
        },
        { 
            grow: 2, 
            name:'Título',
            selector:'title',
            sortable: true,
        },
        { 
            grow: 1, 
            name:'Tópico',
            selector:'topic',
            sortable: true
        },
        { 
            grow: 1, 
            name:'Usuario',
            selector:'user',
            sortable: true
        },
        { 
            grow: 1, 
            name:'Fecha',
            selector:'date',
            sortable: true,
            cell: row => <small className='ticket-date'>{row.date}</small>
        },
        { 
            grow: 1, 
            name:'Prioridad',
            selector:'priority',
            sortable: true
        },
        { 
            grow: 1, 
            name:'Estado',
            selector:'status',
            cell: row => <div className='d-flex flex-column align-items-center justify-content-center ticket-status'>
                            <p className='p-0 m-0'>{row.status}</p>
                            {row.agent && <p className='p-0 m-0'> <i className="fas fa-user-lock"></i> {row.agent.username}</p>} 
                        </div>
        },
    ]

    const conditionalRowStyles = [
        {
            when: row => row.priority === 'Emergencia',
            style: {
                backgroundColor: 'rgb(255, 71, 71)',
                color:'white',
                transition:'.3s all linear',
                '&:hover': {
                    background: 'red' ,
                }
            }
        },
        {
            when: row => row.is_taken,
            style: {
                backgroundColor: 'yellow',
                transition:'.3s all linear',
                '&:hover': {
                    background: 'rgb(255, 230, 0)' ,
                }
            }
        },
    ];

    const pagOpts = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItems: true,
        selectAllRowsItemText:'Todos'
    };

    const customStyles = {
        rows:{
            style: {
                fontSize:'.7rem',
                cursor:'pointer',
                '&:hover': {
                    background: 'rgba(114, 107, 121, 0.2)'
                }
            }
        }
    }

    return (
        <DataTable
            columns={cols} 
            data={filteredItems} 
            pagination={(filteredItems && filteredItems.length < 10) ? false : true}
            paginationComponentOptions={pagOpts}
            onRowClicked={ row => {
                return history.push(`/panel/ticket/${ row.slug }/detail`)
            } }
            customStyles={customStyles}
            noDataComponent='No hay registros'
            conditionalRowStyles={conditionalRowStyles}
            progressPending={loading}
            progressComponent={<Loader />}
        />
    )
}
