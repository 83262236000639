import React from 'react';
import ReactDOM from 'react-dom';

//Estilos en CSS puro
import './App.css';

//Componente principal
import { App } from './App';

//Componente padre
ReactDOM.render(
  <App />,
  document.getElementById('root')
);

