export const PATHS = {
    /**Lista de paths registrados en la aplicación para mejorar separación de código */

    //TICKETS TICKETS TICKETS TICKETS TICKETS TICKETS 
    //TICKETS TICKETS TICKETS TICKETS TICKETS TICKETS 
    //TICKETS TICKETS TICKETS TICKETS TICKETS TICKETS 

    //Índice de tickets generales
    generalTickets : '/panel/ticket/general',
    
    //Índice de tickets abiertos
    openTickets : '/panel/ticket/open',

    //Índice de tickets derivados
    derivedTickets : '/panel/ticket/derived',

    //Índice de tickets pendientes
    pendantTickets : '/panel/ticket/pendant',

    //Índice de tickets solucionados
    solvedTickets : '/panel/ticket/solved',

    //Índice de tickets cerrados
    closedTickets : '/panel/ticket/closed',

    //Crear tickets
    createTickets: '/panel/ticket/create',

    //Detalle de tickets
    detailTickets: '/panel/ticket/:slug/detail',

    //TASKS TASKS TASKS TASKS TASKS TASKS TASKS TASKS TASKS TASKS
    //TASKS TASKS TASKS TASKS TASKS TASKS TASKS TASKS TASKS TASKS
    //TASKS TASKS TASKS TASKS TASKS TASKS TASKS TASKS TASKS TASKS

    //Índice de tareas generales
    generalTasks: '/panel/tasks/general',

    //Índice de tareas abiertas
    openTasks: '/panel/tasks/open',
    
    //Índice de tareas derivadas
    derivedTasks: '/panel/tasks/derived',
    
    //Índice de tareas derivadas
    finishedTasks: '/panel/tasks/finished',
    
    //Índice de tareas derivadas
    aprovedTasks: '/panel/tasks/aproved',

    //Detalle de tareas
    detailTasks: '/panel/task/:slug/detail',

    //Crear tareas:
    createTasks: '/panel/task/create',
    

    //OPTIONS OPTIONS OPTIONS OPTIONS OPTIONS OPTIONS OPTIONS 
    //OPTIONS OPTIONS OPTIONS OPTIONS OPTIONS OPTIONS OPTIONS 
    //OPTIONS OPTIONS OPTIONS OPTIONS OPTIONS OPTIONS OPTIONS 

    //Panel de información de perfil
    panelConfigProfile: '/panel/config/profile',

    //Panel de búsqueda avanzada
    panelCustomSearch: '/panel/custom/search',

    //Panel de gráficos de promedios tareas y tickets en general y tareas/ticket por usuario
    panelCustomCharts: '/panel/custom/charts',

    /**CLIENTS CLIENTS CLIENTS CLIENTS CLIENTS CLIENTS CLIENTS 
     * CLIENTS CLIENTS CLIENTS CLIENTS CLIENTS CLIENTS CLIENTS
     * CLIENTS CLIENTS CLIENTS CLIENTS CLIENTS CLIENTS CLIENTS
     * CLIENTS CLIENTS CLIENTS CLIENTS CLIENTS CLIENTS CLIENTS**/

    clientTickets: '/panel/customer/:type',
    clientGeneralTickets: '/panel/customer/ticket',
    clientSolvedTickets: '/panel/customer/solved',
    clientClosedTickets: '/panel/customer/closed',
    clientPendantTickets: '/panel/customer/pendant',

}