import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { createClientTicket, getCreateClientTicketInfo } from '../../../../actions/ticket-create';
import { PATHS } from '../../../../types/paths';

export const TicketCreateForm = () => {
    const { priorities, topics } = useSelector(state => state.create);
    const dispatch = useDispatch(); 
    const history = useHistory();

    const [error, setError] = useState({
        status:false,
        message:''
    });

    const initialState = {abono:'', tema:'', prioridad:'',titulo:'',contenido:'',archivo:''}
    const [form, setForm] = useState(initialState);

    const {abono, tema, prioridad, titulo, contenido} = form;

    const handleChange = e => {
        if(e.target.name === 'archivo') {
            return setForm({
                ...form,
                [e.target.name]: e.target.files[0]
            })
        }

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async(e) => {
        e.preventDefault();

        const entries = Object.entries(form);
        const entriesFilter = entries.filter( entry => !entry[1] && entry[0] !== 'archivo');
        
        if(entriesFilter.length > 0) {
            return setError({
                status:true,
                message:'Debe completar los campos correspondientes'
            })
        }

        let 
            fd = new FormData(),
            gradeForApproval = 0;

        for(let i = 0; i < entries.length; i++) {
            if((entries[i][0] === 'titulo' || entries[i][0] === 'contenido') && entries[i][1]) {
                fd.append(`${entries[i][0]}`,entries[i][1])
                gradeForApproval++;
            }
            if(entries[i][0] === 'prioridad') {
                const aux = priorities.filter(priority => priority.name === prioridad);
                try {
                    fd.append(`${entries[i][0]}`, aux[0].id);
                    gradeForApproval++;
                } catch (error) {
                    setError({status:true,message:'Complete los campos correctamente'});
                }
            }
            if(entries[i][0] === 'abono') {
                const aux = topics.filter( topic => topic.name === entries[i][1])
                try {
                    fd.append(`${entries[i][0]}`, aux[0].id);
                    gradeForApproval++;
                } catch (err) {
                    setError({status:true,message:'Complete los campos correctamente'});
                }
            }
            if(entries[i][0] === 'tema') {
                const aux = topics.filter( topic => topic.name === abono)[0].topics.filter( topic => topic.name === tema);
                //console.log('tema',aux)
                try {
                    fd.append(`${entries[i][0]}`, aux[0].id);
                    gradeForApproval++;
                } catch (err) {
                    setError({status:true,message:'Complete los campos correctamente'});
                }
            }
            if(entries[i][0] === 'archivo' && entries[i][1]) {
                fd.append(`${entries[i][0]}`, entries[i][1]);
            } 
        }
        setError({
            status:false,
            message:''
        })

        /** for(var pair of fd.entries()) {
            console.log(pair[0]+ ', '+ pair[1]);
        }
       console.log(`gradeForApproval++`, gradeForApproval)
       console.log(archivo)*/
        if (gradeForApproval === 5) {
            await dispatch(createClientTicket(fd));
            history.push(PATHS.clientGeneralTickets);
            setForm(initialState);
        } else {
            console.log('error',error.message)
        }
    }
    //console.log(abono,topics.filter( topic => topic.name === abono))
    useEffect(() => {
        dispatch(getCreateClientTicketInfo());
    },[dispatch]);
    return (
        <form>
            <div>
                <p className="panel_cliente_crear_ticket_nuevo">Nuevo ticket</p>
                <small className={ 'panel_cliente_crear_ticket_aclaracion ' + (error.status && 'error_color')}>
                    {
                        error.status ? error.message : 'Por favor, complete el formulario para crear un nuevo ticket'
                    }
                </small>
                <div className="panel_cliente_crear_ticket_inputs">
                    <input 
                        required
                        autoComplete="off"
                        type='text' 
                        list="panel_cliente_abono"
                        name="abono"
                        value={abono}
                        onChange={handleChange}
                        placeholder="Abono"
                        onFocus={() => {
                            document.getElementById('panel_cliente_abono').style.display='block';
                        }}
                    />
                        <datalist 
                            id="panel_cliente_abono"
                            onClick={() => {
                                document.getElementById('panel_cliente_abono').style.display='none';
                            }}
                        >
                            {
                                topics && topics.map( abono => ( <option id={abono.id} key={abono.id}>{abono.name}</option> ))
                            }
                        </datalist>
                    <input
                        required
                        autoComplete="off"
                        type='text' 
                        list="panel_cliente_tema"
                        name="tema"
                        value={tema}
                        onChange={handleChange}
                        placeholder="Tema"
                        onFocus={() => {
                            document.getElementById('panel_cliente_tema').style.display='block';
                        }}
                    />
                        <datalist 
                            id="panel_cliente_tema"
                            onClick={() => {
                                document.getElementById('panel_cliente_tema').style.display='none';
                            }}
                        >
                            {
                                (topics && abono && topics.filter( topic => topic.name === abono)[0]) 
                                && topics.filter( topic => topic.name === abono)[0].topics.map( tema => (
                                    <option id={tema.id} key={tema.id}>{tema.name}</option>
                                ))
                            }
                        </datalist>
                    <input
                        required
                        autoComplete="off"
                        type='text' 
                        list="panel_cliente_prioridad"
                        name="prioridad"
                        value={prioridad}
                        onChange={handleChange}
                        placeholder="Prioridad"
                        onFocus={() => {
                            document.getElementById('panel_cliente_prioridad').style.display='block';
                        }}
                    />
                        <datalist 
                            id="panel_cliente_prioridad"
                            onClick={() => {
                                document.getElementById('panel_cliente_prioridad').style.display='none';
                            }}
                        >
                            {
                                priorities && priorities.map( priority => ( 
                                    <option 
                                        id={priority.id} 
                                        key={priority.id}
                                    >
                                        {priority.name}
                                    </option> 
                                ))
                            }
                        </datalist>

                    <input 
                        required
                        autoComplete="off"
                        type='text' 
                        placeholder="Título"
                        name="titulo"
                        value={titulo}
                        onChange={handleChange}
                    />
                    <textarea
                        required
                        placeholder="Contenido"
                        name="contenido"
                        value={contenido}
                        onChange={handleChange}
                    ></textarea>
                    <input 
                        accept="image/*,.pdf"
                        type='file' 
                        name="archivo"
                        onChange={handleChange}
                    />
                </div>
            </div>
            <button
                onClick={handleSubmit}
            > 
                <div>+</div> 
                <small>Crear ticket</small>
            </button>
                <br />
        </form>
    )
}
