export const urlTitle = () => {

    if ( window.location.pathname.includes('my-tickets') ) {
        return myTickets;
    } else if ( window.location.pathname.includes('create') ){
        return createTicket;
    } else if ( window.location.pathname.includes('general') ){
        return indexTicket;
    } else if ( window.location.pathname.includes('solved') ){
        return solvedTickets;
    } else if ( window.location.pathname.includes('closed') ){
        return closedTickets;
    } else if ( window.location.pathname.includes('derived') ){
        return derivedTickets;
    } else if ( window.location.pathname.includes('profile') ){
        return profile;
    } else if ( window.location.pathname.includes('detail') ){
        return ticketDetail;
    } else {
        return myTickets;
    }

}

const myTickets = {
    'title' : 'Mis tickets',
    'description' : 'Todos los tickets relacionados a esta cuenta',
}

const createTicket = {
    'title' : 'Crear ticket',
    'description' : 'Llena los campos para crear un nuevo ticket',
}

const indexTicket = {
    'title' : 'Todos los tickets',
    'description' : 'Todos los tickets visibles por el usuario',
}

const solvedTickets = {
    'title' : 'Solucionados',
    'description' : 'Todos los tickets solucionados',
}

const closedTickets = {
    'title' : 'Cerrados',
    'description' : 'Todos los tickets cerrados',
}

const derivedTickets = {
    'title' : 'Derivados',
    'description' : 'Todos los tickets derivados',
}

const profile = {
    'title' : 'Perfil de usuario',
    'description' : 'Visualización del perfil del usuario'
}

const ticketDetail = () => {
    return {
        'title' : 'Ticket #'+ 123123 ,
        'description' : 'Visualización a detalle del ticket' 
    }
}


export const commentDate = fecha => {

    let d = new Date(fecha);
    let year = d.getFullYear(), month = d.getMonth()+1, dt = d.getDate(), hr = d.getHours(), mn = d.getMinutes();
    if (dt < 10) { dt = '0' + dt; }
    if (month < 10) { month = '0' + month; }
    if(hr < 10) { hr = '0' + hr }
    if(mn < 10) { mn = '0' + mn }
    let fullDate = year+'-' + month + '-'+dt+ ' ' + hr + ':' + mn;

    return fullDate;
}

export const days = number => {
    /**Regresa la cantidad ingresada de días en milisegundos */
    return number * 86400000;
}

export const formatDateWithTime = fecha => {
    let d = new Date(Date.parse(fecha));
    let year = d.getFullYear() + "\n", month = d.getMonth() + 1, dt = d.getDate(), hr = d.getHours(), mn = d.getMinutes();
    
    if (dt < 10) { dt = '0' + dt; }
    if (month < 10) { month = '0' + month; }
    if(hr < 10) { hr = '0' + hr }
    if(mn < 10) { mn = '0' + mn }

    //console.log("día y hora de creación: ", dt + '-' + month + '-' + year + hr + ':' + mn + 'hrs');
    return dt +'-' + month + '-'+ year + "\n" + hr + ':' + mn + 'hrs';

}

export const stringForDate = stringDate => {
    const fullDate = stringDate, yr = fullDate.slice(6,10), mt = fullDate.slice(3,5), day = fullDate.slice(0,2), hr = fullDate.slice(11,13),mn = fullDate.slice(14,16);
    return Date(yr,mt,day,hr,mn)
}

export let commentClass = tipo => {
    if( tipo ) {
        
        if( tipo === 'Cliente' ) {
            return 'seccion-detalle-comentario-usuario-actual';
        } else if( tipo === 'Agente' ) {
            return 'seccion-detalle-comentario-usuario';
        } else if( tipo === 'Interno' ) {
            return 'seccion-detalle-comentario-usuario interno';
        } else {
            return 'seccion-detalle-comentario-usuario interno';
        }
    } else {
        return "";
    }
}


export const toTheTop = () => {
    return window.scrollTo({ top: 0, behavior: 'smooth' });
}