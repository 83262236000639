import { TYPES } from "../types/types";

export const authReducer = ( state = {}, action ) => {

    switch (action.type) {

        case TYPES.login:
            return {
                ...state,
                user: {...action.payload.user},
                role: action.payload.role,
            };

        case TYPES.logout:
            return {};
    
        default:
            return state;
    }

}

/*{
    uid: 'dfvbnmrtyui67893748324',
    name: 'Cristian',
}*/