import { TYPES } from "../types/types"

export const setHeader = (title, description) => ({
    type: TYPES.setHeader,
    payload: {
        title: title,
        description: description,
    },
})

export const deleteInfoHeader = () => ({
    type: TYPES.deleteHeader,
})