import { TYPES } from "../types/types";

const initialState = {
    title:'',
    description:'',
    ticket_count:{}
}
export const headerReducer = ( state = initialState, action ) => {

    switch (action.type) {

        case TYPES.setHeader:
            //console.log('headerReducer')
            return {
                ...state,
                title: action.payload.title,
                description: action.payload.description,
            };

        case TYPES.getTicketsCount:
            return {
                ...state,
                ticket_count: {...action.payload}
            }

        case TYPES.deleteHeader:
            return {
                ...state,
                title:'',
                description:''
            };

        default:
            return state;
    }

}
