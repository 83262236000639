import { TYPES } from "../types/types";

export const userReducer = (state = {}, action) => {
    switch (action.type) {
        case TYPES.setUserProfile:
            return {
                ...state,
                ...action.payload
            }

        case TYPES.deleteUserInfo:
            return {}
    
        default:
            return state;
    }
}