export const TYPES = {

    //Authorization action types
    login : '[AUTH] Login',
    logout : '[AUTH] Logout',
    
    //UI action types
    uiError : '[UI] Set error',
    uiNoError : '[UI] No error',
    uiAuthError : '[UI] Set auth error',
    uiAuthNoError : '[UI] No auth error',

    //UI Loading action types
    uiLoading : '[UI] It\'s loading',
    uiNotLoading : '[UI] It\'s not loading',

    //LoadedTickets action types
    getTicketsCount : '[TICKET] get user ticket count',
    setTickets : '[TICKET] set tickets in storage',
    takeOrRelease: '[TICKET] if it\'s take or release',
    deleteTickets:'[TICKET] eliminate tickets',

    //Ticket creation action types
    loadCompanyPriority:'[CREATE] loads ticket company and priority',
    setCompanyPriority:'[CREATE] set company and priority on store',
    createTickets:'[CREATE] submit to create',
    deleteCreationInfo:'[Create] eliminate ticket creation info',

    //setting basics in header
    setHeader : '[HEADER] setHeading',
    deleteHeader : '[HEADER] eliminate heading info',

    //ticket details
    setTicketDetail : '[Detail] set ticket detail',
    deleteDetail: '[Detail] eliminate actual detail',
    setTicketComments: '[Detail] set ticket comment',
    setTicketInteractions: '[Detail] set ticket interactions',
    setTicketTime: '[Detail] set ticket time spent',
    
    //Update ticket state
    updateTicketDetail: '[Detail] update ticket detail',

    //LoadedTasks action types
    setTasks: '[TASK] set tasks in storage',
    deleteTasks: '[TASK] eliminate tasks',
    loadCreateTask: '[TASK] loads task info',
    setCreateTask:'[TASK] set task info on store',

    //Task details
    setTaskDetail: '[TASK] set task detail',
    setTaskComments: '[TASK] set task comments',
    setTaskInteractions: '[TASK] set task interactions',
    setTaskTime: '[TASK] set task time spent',

    //update task state
    updateTaskDetail: '[TASK] update task detail',



    //User profile config
    setUserProfile: '[USER] set user profile info in store',
    deleteUserInfo: '[USER] delete user profile info in store',

    //Custom Searchbar
    setSearchInfo: '[SEARCH] set Info to start searching',

    //Custom info charts
    setChartsInfo: '[CHARTS] set info to visualize in charts',
    deleteChartsInfo: '[CHARTS] deletes info for activity charts',

    //Client action types
    setCreateClientTicket: '[CLIENT] set info need to create tickets with client role'

}